// 添加 css 依赖
import '@/common/css/index.scss';
import './css/swiper.css';
import './index.scss';
// 添加 JS 依赖
// import $ from 'jquery';
import Zepto from 'zepto-webpack';

Zepto(function() {
  var $pagination = $('.swiper-pagination');
  var $button = $('.button');
  /* eslint-disable */
  new Swiper('.swiper-container', {
    pagination: '.swiper-pagination',
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination'
    },
    on: {
      resize: function() {
        window.location.reload();
      },
      slideChangeTransitionStart: function() {
        if (this.activeIndex === 1 || this.activeIndex === 6) {
          $pagination.removeClass('white');
          $button.removeClass('bg');
        } else {
          $pagination.addClass('white');
          $button.addClass('bg');
        }
      }
    }
  });
});
